import React from "react"
import {
  ContactForm,
  Layout,
  SyllabusBanner,
  SyllabusHeader,
  HelmetWrapper,
  SyllabusTexts,
} from "../components"
import Jakub from "../images/photos/jakub.jpeg"
import SyllabusSlider from "../components/syllabus/syllabus-slider"
import { SYLLABUS_SLIDER } from "../model/syllabus-slider-data"
import OgImage from "../images/syllabus-og-images/1.png"

const LECTURER = [
  {
    src: Jakub,
    alt: "",
    personName: "Jakub Kříž",
    text: [
      "Jakub se narodil spolu s internetem. Svůj první linuxový server zprovoznil, když mu bylo 13 a od té doby se věnuje programování. Posledních 10 let spolupracuje s firmou Optimics, kde se specializuje na on-line analytické technologie a etický přístup k trackingu.",
      "Je autorem nespočtu rozsáhlých implementací analytických a marketingových nástrojů napříč internetem a je prakticky nemožné se při brouzdání po webu nesetkat s jeho prací. Létá na paraglidu, staví pivovar, hraje v divadle a má rád dobré techno.",
    ],
    linkedInProfile: "//linkedin.com/in/jakubkrizanalytics",
  },
]

const NavrhMereni: React.FC = () => {
  return (
    <Layout>
      <HelmetWrapper title="Návrh měření" ogImage={OgImage} />

      <SyllabusHeader
        orangeText="Návrh měření"
        numberOfLesson="1/13"
      ></SyllabusHeader>
      <SyllabusBanner data={LECTURER} />

      <SyllabusTexts>
        <div>změna mindsetu na event based</div>
        <div>
          extrakce byznysových potřeb a konverze do architektonické dokumentace
          analytiky
        </div>
        <div>
          konverze architektury do návrhu konkrétního technického řešení (GA4,
          GTM, SGTM, BQ, Looker Studio, atd.) (rámcové představení, bude
          rozpracováno dále), příprava zadávací dokumentace
        </div>
        <div>návrh datalayer</div>
        <div>komunikace s vývojáři</div>
      </SyllabusTexts>

      <SyllabusSlider data={SYLLABUS_SLIDER} />

      <ContactForm
        heading="Máš otázku?"
        subheading="Napiš si o odpověď Adamovi"
      />
    </Layout>
  )
}

export default NavrhMereni
